
import {  PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelatorioDespesaClassificacao extends PageBase { 

    service = new RelatorioService();
    item:any = []
    classificacao:any = []
    ParcelasClassificacaoes:any = []

    parcelas:any = []

    overlay: boolean = false; 
    filter:any = {};

    mounted() {
        this.filter = this.$route.query;
        this.overlay = true;  

        this.service.ListarRelatorioClassificacao(this.filter.empreendimentoId, this.filter.empresaId, this.filter.dataInicioEmissao, this.filter.dataFimEmissao)
            .then(
                res => {
                    this.item = res.data.despesaClassificacoes;

                        this.item.forEach(x => {
                           x.classificacoes.forEach(element => {
                               this.classificacao.push(element)
                           });
                        });

                        this.classificacao.forEach(y => {
                            this.ParcelasClassificacaoes.push(y.parcelasClassificacao)
                        });

                        this.ParcelasClassificacaoes.forEach(element => {
                            element.forEach(item => {
                                 this.parcelas.push(item)
                            });
                        }); 
                 },
                err => {
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            ).finally(() =>{ 
                this.overlay = false;
            });
    } 
}
